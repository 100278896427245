$(document).ready(function() {

	function add() {
		// console.log(items[i]);
		$(items[i]).addClass('product-item-wrap_long');
	}

	var items = $('.product-block').children();
	var long1 = 1;
	var long2 = 6;
	var step = 8;



	for (var i = 0; i < items.length; i++) {
		switch (i) {
			case long1:
				add();
				long1 += step;
				break;
			case long2:
				add();
				long2 += step;
				break;
		}
  }
  
  $(".projects__gallery").lightGallery({
    selector: ".product-item",
    download: false,
    loadYoutubeThumbnail: false,
    loadVimeoThumbnail: false,
    getCaptionFromTitleOrAlt:false,
    exThumbImage: 'data-exthumbimage' 
  });

})